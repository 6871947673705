import { Link, useNavigate, useSearchParams } from '@remix-run/react';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import shuffle from 'lodash/shuffle';
import pluralize from 'pluralize';
import {
  type RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  DndProvider,
  type DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Controller,
  FormProvider,
  useController,
  useForm,
  useFormContext,
  type UseFormSetValue,
  useFormState,
} from 'react-hook-form';
import Select from 'react-select';
import { type ITimezoneOption } from 'react-timezone-select';
import { useDebounce, useEffectOnce } from 'react-use';
import useSWR from 'swr';

import {
  type DtoBlock,
  type DtoBrand,
  EnumsGamePackAudience,
  type EnumsGamePackChangeLevel,
  EnumsGamePackCompetitionLevel,
  EnumsGamePackDifficulty,
  EnumsGamePackInstructionRule,
  EnumsGamePackLeaderboardRule,
  EnumsGamePackMakeUnitsFrom,
  EnumsGamePackMakeup,
  EnumsGamePackVersion,
  EnumsSharedAssetPurpose,
  type ModelsGameAvailability,
  type ModelsPlaybackSettings,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useMakeHostViewAutoloadHandler } from '../../../hooks/useLoadGame';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { apiService } from '../../../services/api-service';
import { RoleUtils } from '../../../types';
import { type GamePack, type GamePackShowcaseCard } from '../../../types/game';
import { assertExhaustive } from '../../../utils/common';
import { canMove } from '../../../utils/dnd';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { useSnapshot } from '../../../utils/valtio';
import { BrandBlockPicker, useBrandBlockFilter } from '../../Brand';
import { BannerWarningMessages } from '../../common/BannerWarningMessage';
import { CollapsibleSection } from '../../common/CollapsibleSection';
import { type Option } from '../../common/Utilities';
import { type FAQGroup } from '../../FAQ';
import { HeaderLayout, RawSearchBarV2 } from '../../Header';
import { FilledCheckIcon } from '../../icons/CheckIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { HostIcon } from '../../icons/HostIcon';
import { LPLogo } from '../../icons/LPLogo';
import { MenuIcon } from '../../icons/MenuIcon';
import { NewWindowIcon } from '../../icons/NewWindowIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import { Loading } from '../../Loading';
import { OneTimePurchasePriceEditor } from '../../OneTimePurchase';
import { usePromptTemplate } from '../../PromptTemplate';
import { useOpenPromptTemplatePickerModal } from '../../PromptTemplate/PromptTemplatePicker';
import { ReactTimezoneSelectTypeFixed } from '../../ReactTimezoneSelectTypeFixed';
import {
  BlockCover,
  BlockEditorStore,
  BlockEditorStoreProvider,
} from '../../RoutedBlock';
import { useOpenShareAssetPickerModal } from '../../SharedAsset';
import { SwitcherControlled } from '../../Switcher';
import { useUser } from '../../UserContext';
import { TTSPreviewButton } from '../../VoiceOver/TTSPreviewButton';
import { BlockIcon, BlockRecordingIcon } from '../Blocks';
import { BlockEditor, useOndRecordingDetails } from '../Blocks/Common/Editor';
import { BlockKnifeUtils } from '../Blocks/Shared';
import { PrimeWarningMessage } from '../GameCenter';
import { GameUtils } from '../GameUtils';
import { GamePackChangeConfirm } from './GamePackChangeConfirm';
import { GamePackV2PlaybackConfigurator } from './GamePackPlaybackConfigurator';
import { GamePackMarketingSettingsEditor } from './GamePackV2EditorMarketingSettings';
import {
  useBrandMap,
  useFakePlayedHistory,
  useMakePlaybackPreview,
} from './hooks';
import {
  CategoriesField,
  CoverField,
  DescriptionField,
  Featured as FeaturedField,
  PackNameField,
  PlayerRangeEditor,
  PromotionalAssets,
  RichDescriptionField,
  TeamRandomization,
  useSubmit,
  useTriggerCancelConfirmModal,
} from './Shared';
import { GPv2TemplateUtils } from './template';
import {
  type BlockPreviewMetadata,
  type GamePackEditorControlledProps,
  type GamePackEditorFieldProps,
  type GamePackEditorFormData,
} from './types';
import { GamePackEditorUtils, GamePackUtils } from './utils';

function Header(props: {
  q: string | null;
  pack: Nullable<GamePack>;
  onCancel: () => void;
  onSubmit: (confirm?: {
    level: EnumsGamePackChangeLevel;
    note?: string;
  }) => void;
  disabledReason: 'loading' | 'processing' | 'errors' | null;
  needConfirm: boolean;
}): JSX.Element {
  const { q } = props;
  const isAdmin = RoleUtils.isAdmin(useUser());
  const [value, setValue] = useState<string | null>(q);
  const [{ blockUniverse }, setFilter] = useBrandBlockFilter();
  const [openConfirm, setOpenConfirm] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { isSubmitSuccessful, isDirty } =
    useFormState<GamePackEditorFormData>();

  const [wasSubmitSuccessful, setWasSubmitSuccessful] = useState(false);
  useEffect(() => {
    if (isSubmitSuccessful) {
      setWasSubmitSuccessful(true);
    }
  }, [isSubmitSuccessful]);
  useEffect(() => {
    if (isDirty) {
      setWasSubmitSuccessful(false);
    }
  }, [isDirty]);

  useLayoutEffect(() => {
    if (q === null) setValue(null);
  }, [q]);

  useDebounce(
    () => {
      if (value === null || value === q) return;
      setFilter('q', value, !blockUniverse);
    },
    500,
    [value]
  );

  useOutsideClick(ref, () => setOpenConfirm(false));

  const navigate = useNavigate();
  const confirmCancel = useTriggerCancelConfirmModal();
  const playNowUrl = props.pack
    ? GamePackUtils.GetPlaytestUrl(props.pack, false)
    : null;
  const handlePlayNow = useLiveCallback(async () => {
    if (!playNowUrl) return;
    if (isDirty) {
      const response = await confirmCancel();
      if (response.result === 'canceled') return;
    }
    navigate(playNowUrl);
  });
  const handleLoadGame = useMakeHostViewAutoloadHandler(props.pack);
  const handleHostLive = useLiveCallback(async () => {
    if (isDirty) {
      const response = await confirmCancel();
      if (response.result === 'canceled') return;
    }
    handleLoadGame();
  });
  const cohostUrl = props.pack
    ? GamePackUtils.GetCohostUrl(props.pack, false)
    : null;
  const handleCohostNow = useLiveCallback(async () => {
    if (!cohostUrl) return;
    if (isDirty) {
      const response = await confirmCancel();
      if (response.result === 'canceled') return;
    }
    navigate(cohostUrl);
  });
  const handleChangeConfirm = useLiveCallback(
    (level: EnumsGamePackChangeLevel, note?: string) => {
      props.onSubmit({ level, note });
      setOpenConfirm(false);
    }
  );

  return (
    <HeaderLayout
      containerClassName={`${isAdmin ? 'bg-admin-red' : ''}`}
      fill
      left={
        <div className='flex items-center gap-4'>
          <LPLogo type={isAdmin ? 'admin' : 'default'} />
          {props.pack?.cohostSettings?.enabled && (
            <button
              className='btn-primary w-40 h-10 flex flex-row justify-center items-center'
              type='button'
              onClick={handleCohostNow}
              disabled={!props.pack || !!props.disabledReason}
            >
              <HostIcon className='w-3.5 h-3.5 fill-current mr-2' />
              Cohost
            </button>
          )}
          <button
            className='btn-primary w-40 h-10 flex flex-row justify-center items-center'
            type='button'
            onClick={handleHostLive}
            disabled={!props.pack || !!props.disabledReason}
          >
            <HostIcon className='w-3.5 h-3.5 fill-current mr-2' />
            Host Live
          </button>
          <button
            className='btn-primary w-40 h-10 flex flex-row justify-center items-center'
            type='button'
            onClick={handlePlayNow}
            disabled={!playNowUrl || !!props.disabledReason}
          >
            <PlayIcon className='w-3.5 h-3.5 fill-current mr-2' />
            Play Now
          </button>
        </div>
      }
      right={
        <>
          <RawSearchBarV2
            value={value}
            setValue={setValue}
            bgClassName='bg-black'
          />
          <button
            className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
            type='button'
            onClick={props.onCancel}
          >
            {isDirty ? 'Cancel' : 'Close'}
          </button>
          <div ref={ref} className='relative'>
            <button
              className='btn-primary w-34 h-10 flex flex-row justify-center items-center gap-2'
              type='button'
              disabled={!!props.disabledReason || !isDirty}
              onClick={() => {
                if (props.needConfirm) {
                  setOpenConfirm(true);
                } else {
                  props.onSubmit();
                }
              }}
            >
              {wasSubmitSuccessful && !isDirty ? (
                <>
                  <FilledCheckIcon />
                  Saved
                </>
              ) : (
                <>
                  {props.disabledReason === 'loading' && (
                    <Loading text='' imgClassName='w-5 h-5' />
                  )}
                  Save
                </>
              )}
            </button>
            {openConfirm && (
              <div className='absolute right-0 mt-1'>
                <GamePackChangeConfirm onClick={handleChangeConfirm} />
              </div>
            )}
          </div>
        </>
      }
    />
  );
}

type PlaybackSettingsFieldProps = GamePackEditorFieldProps & {
  settings: Nullable<ModelsPlaybackSettings>;
  unit: string;
  onChange: <T extends keyof ModelsPlaybackSettings>(
    key: T,
    val: ModelsPlaybackSettings[T]
  ) => void;
  hide?: boolean;
};

function GameMakeupField(props: PlaybackSettingsFieldProps) {
  const options = useInstance<Option<EnumsGamePackMakeup>[]>(() => [
    {
      label: 'Levels (with selector)',
      value: EnumsGamePackMakeup.GamePackMakeupMultipleLevels,
    },
    {
      label: 'Rounds (no selector)',
      value: EnumsGamePackMakeup.GamePackMakeupMultipleRounds,
    },
    {
      label: 'One Big Game',
      value: EnumsGamePackMakeup.GamePackMakeupOneBigGame,
    },
  ]);

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsGamePackMakeup>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <label>
      <div className='mb-2 font-bold'>Game Makeup</div>
      <Select<Option<EnumsGamePackMakeup>>
        options={options}
        value={options.find((opt) => opt.value === props.settings?.gameMakeup)}
        classNamePrefix='select-box-v2'
        className='w-full'
        styles={styles}
        placeholder='Select an option'
        onChange={(option) => {
          props.onChange?.('gameMakeup', option?.value);
        }}
      />
    </label>
  );
}

function useUnitLabel(makeup: Nullable<EnumsGamePackMakeup>) {
  switch (makeup) {
    case EnumsGamePackMakeup.GamePackMakeupMultipleLevels:
      return 'Levels';
    case EnumsGamePackMakeup.GamePackMakeupMultipleRounds:
      return 'Rounds';
    case EnumsGamePackMakeup.GamePackMakeupOneBigGame:
      return 'Game';
    case undefined:
    case null:
      return 'Units';
    default:
      assertExhaustive(makeup);
      return 'Units';
      break;
  }
}

function MakeUnitsFromField(props: PlaybackSettingsFieldProps) {
  const options = useInstance(() =>
    GamePackEditorUtils.MakeOptionsFromEnumValues<EnumsGamePackMakeUnitsFrom>(
      Object.values(EnumsGamePackMakeUnitsFrom)
    )
  );

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsGamePackMakeUnitsFrom>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <label>
      <div className='mb-2 font-bold'>Make {props.unit} From</div>
      <Select<Option<EnumsGamePackMakeUnitsFrom>>
        options={options}
        value={options.find(
          (opt) => opt.value === props.settings?.makeUnitsFrom
        )}
        classNamePrefix='select-box-v2'
        className='w-full'
        styles={styles}
        placeholder='Select an option'
        onChange={(option) => {
          props.onChange('makeUnitsFrom', option?.value);
        }}
        isDisabled={props.disabled}
      />
    </label>
  );
}

function DefaultUnitsPerSessionField(props: PlaybackSettingsFieldProps) {
  return (
    <label>
      <div className='mb-2 font-bold'>Default {props.unit} Per Session</div>
      <input
        className='field h-13 mb-0'
        onChange={(event) =>
          props.onChange(
            'defaultUnitsPerSession',
            parseInt(event.target.value, 10)
          )
        }
        type='number'
        placeholder='Type in an integer'
        disabled={props.disabled}
        value={props.settings?.defaultUnitsPerSession}
      />
    </label>
  );
}

function InstructionRulesField(props: PlaybackSettingsFieldProps) {
  const options = useInstance(() =>
    GamePackEditorUtils.MakeOptionsFromEnumValues<EnumsGamePackInstructionRule>(
      Object.values(EnumsGamePackInstructionRule)
    )
  );

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsGamePackInstructionRule>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <label>
      <div className='mb-2 font-bold'>Instruction Rules</div>
      <Select<Option<EnumsGamePackInstructionRule>>
        options={options}
        value={options.find(
          (opt) => opt.value === props.settings?.instructionRules
        )}
        classNamePrefix='select-box-v2'
        className='w-full'
        styles={styles}
        placeholder='Select an option'
        onChange={(option) => {
          props.onChange('instructionRules', option?.value);
        }}
        isDisabled={props.disabled}
      />
    </label>
  );
}

function LeaderboardRulesField(props: PlaybackSettingsFieldProps) {
  const options = useInstance(() =>
    GamePackEditorUtils.MakeOptionsFromEnumValues<EnumsGamePackLeaderboardRule>(
      Object.values(EnumsGamePackLeaderboardRule)
    )
  );

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsGamePackLeaderboardRule>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <label>
      <div className='mb-2 font-bold'>Leaderboard Rules</div>
      <Select<Option<EnumsGamePackLeaderboardRule>>
        options={options}
        value={options.find(
          (opt) => opt.value === props.settings?.leaderboardRules
        )}
        classNamePrefix='select-box-v2'
        className='w-full'
        styles={styles}
        placeholder='Select an option'
        onChange={(option) => {
          props.onChange('leaderboardRules', option?.value);
        }}
        isDisabled={props.disabled}
      />
    </label>
  );
}

function LockUnitsPerSessionField(props: PlaybackSettingsFieldProps) {
  return (
    <label
      className={`w-2/3 justify-between items-center ${
        props.hide ? 'hidden' : 'flex'
      }`}
    >
      <p className='mr-4 text-xs font-light'>Lock {props.unit} Per Session</p>
      <SwitcherControlled
        name='lockUnitsPerSession'
        checked={props.settings?.lockUnitsPerSession || false}
        onChange={(val) => props.onChange('lockUnitsPerSession', val)}
        disabled={props.disabled}
      />
    </label>
  );
}

function RandomUnitOrderField(props: PlaybackSettingsFieldProps) {
  return (
    <label className='flex w-2/3 justify-between items-center'>
      <p className='mr-4 text-xs font-light'>
        Random {pluralize(props.unit, 1)} Order
      </p>
      <SwitcherControlled
        name='randomizeUnitOrder'
        checked={props.settings?.randomizeUnitOrder || false}
        onChange={(val) => props.onChange('randomizeUnitOrder', val)}
        disabled={props.disabled}
      />
    </label>
  );
}

function ResumeFromLastUnitPlayedField(props: PlaybackSettingsFieldProps) {
  return (
    <label className='flex w-2/3 justify-between items-center'>
      <p className='mr-4 text-xs font-light'>
        Resume from last{' '}
        <span className='lowercase'>{pluralize(props.unit, 1)}</span> played
      </p>
      <SwitcherControlled
        name='resumeFromLastUnitPlayed'
        checked={props.settings?.resumeFromLastUnitPlayed || false}
        onChange={(val) => props.onChange('resumeFromLastUnitPlayed', val)}
        disabled={props.disabled}
      />
    </label>
  );
}

function PlaybackSettingsEditor(props: GamePackEditorControlledProps) {
  const makeup = props.watch('playbackSettings.gameMakeup');
  const disabled = !makeup;
  const unit = useUnitLabel(makeup);

  const fieldProps = { ...props, disabled, unit };

  return (
    <Controller<GamePackEditorFormData, 'playbackSettings'>
      name='playbackSettings'
      control={props.control}
      render={({ field }) => {
        const onFieldChange = <T extends keyof ModelsPlaybackSettings>(
          key: T,
          val: ModelsPlaybackSettings[T]
        ) => {
          field.onChange({ ...field.value, [key]: val });
        };

        return (
          <div className='w-full flex flex-col gap-4'>
            <GameMakeupField
              {...fieldProps}
              settings={field.value}
              onChange={onFieldChange}
            />
            <MakeUnitsFromField
              {...fieldProps}
              settings={field.value}
              onChange={onFieldChange}
            />
            <DefaultUnitsPerSessionField
              {...fieldProps}
              settings={field.value}
              onChange={onFieldChange}
            />
            <InstructionRulesField
              {...fieldProps}
              settings={field.value}
              onChange={onFieldChange}
            />
            <LeaderboardRulesField
              {...fieldProps}
              settings={field.value}
              onChange={onFieldChange}
            />
            <div className='w-full flex flex-col gap-3'>
              <LockUnitsPerSessionField
                {...fieldProps}
                settings={field.value}
                onChange={onFieldChange}
                hide
              />
              <RandomUnitOrderField
                {...fieldProps}
                settings={field.value}
                onChange={onFieldChange}
              />
              <ResumeFromLastUnitPlayedField
                {...fieldProps}
                settings={field.value}
                onChange={onFieldChange}
              />
            </div>
          </div>
        );
      }}
    />
  );
}

function MarkAsNewField(props: GamePackEditorFieldProps) {
  const markAsNew = props.watch('detailSettings')?.markAsNew;
  const result = GamePackUtils.ValidMarkAsNew(markAsNew);
  return (
    <label className='flex w-2/3 justify-between items-center'>
      <p className='mr-4 text-xs font-light'>Mark as New (4 weeks)</p>
      <Controller<GamePackEditorFormData, 'detailSettings.markAsNew'>
        name='detailSettings.markAsNew'
        control={props.control}
        render={({ field }) => (
          <div className='flex items-center relative'>
            <SwitcherControlled
              name={field.name}
              checked={result.valid}
              onChange={(checked) => {
                field.onChange(checked ? GamePackUtils.MakeMarkAsNew() : null);
              }}
              disabled={props.disabled}
            />
            {result.valid && (
              <p
                className='text-xs font-light absolute whitespace-nowrap 
              -right-2 transform translate-x-full'
              >
                {result.note}
              </p>
            )}
          </div>
        )}
      />
    </label>
  );
}

function GameTypeField(props: GamePackEditorFieldProps) {
  return (
    <label>
      <div className='mb-2 font-bold'>Game Type</div>
      <Controller<GamePackEditorFormData, 'detailSettings.gameType'>
        name='detailSettings.gameType'
        control={props.control}
        rules={{ maxLength: 50 }}
        render={({ field, fieldState }) => (
          <input
            {...field}
            className={
              fieldState.error ? 'field-error h-13 mb-0' : 'field h-13 mb-0'
            }
            placeholder='Type Game Type Text'
            maxLength={50}
          />
        )}
      />
    </label>
  );
}

function DurationField(props: GamePackEditorFieldProps) {
  const formatOption = useCallback((seconds: number) => {
    const minutes = Math.ceil(seconds / 60);
    return {
      label: `${minutes} Minutes`,
      value: seconds,
    };
  }, []);

  const options = useInstance(() =>
    [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 75, 90].map((min) =>
      formatOption(min * 60)
    )
  );

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<number>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <label>
      <div className='mb-2 font-bold'>Duration</div>
      <Controller<GamePackEditorFormData, 'approximateDurationSeconds'>
        name='approximateDurationSeconds'
        control={props.control}
        render={({ field: { value, onChange } }) => (
          <Select<Option<number>>
            options={options}
            value={formatOption(value ?? 0)}
            classNamePrefix='select-box-v2'
            className='w-full'
            styles={styles}
            placeholder='Select game duration...'
            onChange={(option) => {
              onChange(option?.value);
            }}
          />
        )}
      />
    </label>
  );
}

function GameInspiredByField(props: GamePackEditorFieldProps) {
  return (
    <label>
      <div className='mb-2 font-bold'>Game Inspired By</div>
      <Controller<GamePackEditorFormData, 'detailSettings.gameInspiredBy'>
        name='detailSettings.gameInspiredBy'
        control={props.control}
        rules={{ maxLength: 50 }}
        render={({ field, fieldState }) => (
          <input
            {...field}
            className={
              fieldState.error ? 'field-error h-13 mb-0' : 'field h-13 mb-0'
            }
            placeholder='Type Game Inspired By'
            maxLength={50}
          />
        )}
      />
    </label>
  );
}

function ReplayableField(props: GamePackEditorFieldProps) {
  return (
    <label className='flex w-2/3 justify-between items-center'>
      <p className='mr-4 text-xs font-light'>Replayable</p>
      <Controller<GamePackEditorFormData, 'replayable'>
        name='replayable'
        control={props.control}
        render={({ field }) => (
          <SwitcherControlled
            name={field.name}
            checked={field.value || false}
            onChange={field.onChange}
            disabled={props.disabled}
          />
        )}
      />
    </label>
  );
}

function GameDifficultyField(props: GamePackEditorFieldProps) {
  const options = useInstance<Option<EnumsGamePackDifficulty | null>[]>(() => [
    {
      label: 'None',
      value: null,
    },
    {
      label: 'Easy',
      value: EnumsGamePackDifficulty.GamePackDifficultyEasy,
    },
    {
      label: 'Medium',
      value: EnumsGamePackDifficulty.GamePackDifficultyMedium,
    },
    {
      label: 'Hard',
      value: EnumsGamePackDifficulty.GamePackDifficultyHard,
    },
  ]);

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsGamePackDifficulty | null>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <label>
      <div className='mb-2 font-bold'>Game Difficulty</div>
      <Controller<GamePackEditorFormData, 'detailSettings.gameDifficulty'>
        name='detailSettings.gameDifficulty'
        control={props.control}
        render={({ field: { value, onChange } }) => (
          <Select<Option<EnumsGamePackDifficulty | null>>
            options={options}
            value={options.find((opt) => opt.value === value)}
            classNamePrefix='select-box-v2'
            className='w-full'
            styles={styles}
            placeholder='Choose a difficulty level'
            onChange={(option) => {
              onChange(option?.value);
            }}
          />
        )}
      />
    </label>
  );
}

function GameCompetitionLevelField(props: GamePackEditorFieldProps) {
  const options = useInstance<Option<EnumsGamePackCompetitionLevel | null>[]>(
    () => [
      {
        label: 'None',
        value: null,
      },
      {
        label: 'Casual',
        value: EnumsGamePackCompetitionLevel.GamePackCompetitionLevelCasual,
      },
      {
        label: 'Neutral',
        value: EnumsGamePackCompetitionLevel.GamePackCompetitionLevelNeutral,
      },
      {
        label: 'Competitive',
        value:
          EnumsGamePackCompetitionLevel.GamePackCompetitionLevelCompetitive,
      },
    ]
  );

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsGamePackCompetitionLevel | null>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <label>
      <div className='mb-2 font-bold'>Competition Level </div>
      <Controller<GamePackEditorFormData, 'detailSettings.competitionLevel'>
        name='detailSettings.competitionLevel'
        control={props.control}
        render={({ field: { value, onChange } }) => (
          <Select<Option<EnumsGamePackCompetitionLevel | null>>
            options={options}
            value={options.find((opt) => opt.value === value)}
            classNamePrefix='select-box-v2'
            className='w-full'
            styles={styles}
            placeholder='Choose a competition level'
            onChange={(option) => {
              onChange(option?.value);
            }}
          />
        )}
      />
    </label>
  );
}

function GameAudienceField(props: GamePackEditorFieldProps) {
  const options = useInstance<Option<EnumsGamePackAudience>[]>(() => [
    {
      label: 'Requires U.S. Knowledge',
      value: EnumsGamePackAudience.GamePackAudienceUsCentric,
    },
    {
      label: 'Global',
      value: EnumsGamePackAudience.GamePackAudienceGlobal,
    },
  ]);

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsGamePackAudience>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <label>
      <div className='mb-2 font-bold'>Audience</div>
      <Controller<GamePackEditorFormData, 'detailSettings.audience'>
        name='detailSettings.audience'
        control={props.control}
        render={({ field: { value, onChange } }) => (
          <Select<Option<EnumsGamePackAudience>>
            options={options}
            value={options.find((opt) => opt.value === value)}
            classNamePrefix='select-box-v2'
            className='w-full'
            styles={styles}
            placeholder='Choose an audience'
            onChange={(option) => {
              onChange(option?.value);
            }}
          />
        )}
      />
    </label>
  );
}

function GameAvailabilityField(props: GamePackEditorFieldProps) {
  const {
    field: { value: availability, onChange },
  } = useController<GamePackEditorFormData, 'detailSettings.availability'>({
    name: 'detailSettings.availability',
    control: props.control,
  });

  const styles = useMemo(
    () =>
      buildReactSelectStyles<ITimezoneOption>({
        override: {
          control: { height: '40px' },
        },
      }),
    []
  );

  const handleChange = (updated: ModelsGameAvailability | null) => {
    onChange(updated);
  };

  return (
    <div>
      <div className='mb-2'>
        <p className='font-bold'>Game Availability</p>

        <p className='text-icon-gray text-xs'>
          Optional - Games with launch dates may be visible in the library but
          are unplayable until launch date
        </p>
      </div>

      {availability ? (
        <div className='w-full max-w-75'>
          <div className='w-full flex justify-between items-center text-sms text-icon-gray'>
            <p>Choose Future Launch Date</p>
            <button
              type='button'
              className='btn'
              onClick={() => handleChange(null)}
            >
              Remove
            </button>
          </div>
          <div className='mt-2 w-full flex flex-col gap-2'>
            <ReactDatePicker
              className='field w-full h-10 mb-0'
              selected={utcToZonedTime(
                availability.launchDate,
                availability.timezone
              )}
              onChange={(date) => {
                if (!date) return;
                handleChange({
                  launchDate: zonedTimeToUtc(
                    date,
                    availability.timezone
                  ).toISOString(),
                  timezone: availability.timezone,
                });
              }}
              minDate={new Date()}
              dateFormat='MM/dd/yyyy'
            />
            <ReactDatePicker
              className='field h-10 mb-0'
              selected={utcToZonedTime(
                availability.launchDate,
                availability.timezone
              )}
              onChange={(updated) => {
                if (!updated) return;
                // note: there seems to be a bug in the library where manually inputting the time will reset the day.
                // as a workaround, we manually set the time here.
                const date = utcToZonedTime(
                  availability.launchDate,
                  availability.timezone
                );
                date.setHours(updated.getHours());
                date.setMinutes(updated.getMinutes());
                date.setSeconds(0);
                date.setMilliseconds(0);

                handleChange({
                  launchDate: zonedTimeToUtc(
                    date,
                    availability.timezone
                  ).toISOString(),
                  timezone: availability.timezone,
                });
              }}
              minDate={new Date()}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat='h:mm aa'
            />
            <ReactTimezoneSelectTypeFixed
              value={availability.timezone}
              className='select-box text-white'
              classNamePrefix='select-box-v2'
              styles={styles}
              onChange={(updated) => {
                if (!updated) return;
                handleChange({
                  launchDate: zonedTimeToUtc(
                    utcToZonedTime(
                      availability.launchDate,
                      availability.timezone
                    ),
                    updated.value
                  ).toISOString(),
                  timezone: updated.value,
                });
              }}
            />
          </div>
        </div>
      ) : (
        <button
          type='button'
          className='btn-secondary w-full h-10'
          onClick={() =>
            handleChange({
              launchDate: new Date().toISOString(),
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
          }
        >
          Add Launch Date
        </button>
      )}
    </div>
  );
}

function DetailSettingsEditor(
  props: GamePackEditorControlledProps & {
    pack: Nullable<GamePack>;
    setIsUploading: (val: boolean) => void;
    onShowOverlay: (overlay: JSX.Element | null) => void;
  }
) {
  const cover = props.pack?.cover ?? null;
  const featureable = !!props.pack?.id;

  return (
    <div className='w-full flex flex-col gap-4'>
      <CoverField {...props} cover={cover} />
      <div className='w-full flex flex-col gap-3'>
        <ReplayableField {...props} />
        {featureable && <FeaturedField {...props} cover={cover} />}
        {featureable && !!props.pack && (
          <PromotionalAssets {...props} cover={cover} pack={props.pack} />
        )}
        <MarkAsNewField {...props} />
      </div>
      <GameAvailabilityField {...props} />
      <CategoriesField {...props} tags={props.pack?.tags} />
      <GameTypeField {...props} />
      <DurationField {...props} />
      <GameInspiredByField {...props} />
      <DescriptionField {...props} />
      <RichDescriptionField {...props} />
      <GameDifficultyField {...props} />
      <GameCompetitionLevelField {...props} />
      <GameAudienceField {...props} />
    </div>
  );
}

function TeamSettingsEditor(props: GamePackEditorControlledProps) {
  return (
    <div className='w-full flex flex-col gap-4'>
      <PlayerRangeEditor control={props.control} />
      <TeamRandomization {...props} className='mt-7.5' />
    </div>
  );
}

type DragBlockEntry = {
  id: string;
  index: number;
};

function SessionIndicator(props: { metadata?: BlockPreviewMetadata }) {
  const { metadata } = props;
  const singleUnitSection = metadata?.startOfSection && metadata.endOfSection;
  return (
    <div
      className={`absolute rounded w-1 bg-red-002 -left-4 ${
        singleUnitSection
          ? 'h-full'
          : metadata?.startOfSection
          ? 'h-[120%] top-0'
          : metadata?.endOfSection
          ? 'h-[120%] bottom-0'
          : 'h-[120%] top-1/2 transform-gpu -translate-y-1/2'
      }`}
    />
  );
}

function LevelRoundIndicator(props: {
  unit: Nullable<EnumsGamePackMakeUnitsFrom>;
  metadata?: BlockPreviewMetadata;
}) {
  const { unit, metadata } = props;

  switch (unit) {
    case EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromIndividualBlocks:
      return (
        <div className={`absolute rounded w-1 bg-tertiary -left-2 h-full`} />
      );
    case EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromConsecutiveBrandBlocks:
      return (
        <div
          className={`absolute w-1 rounded bg-tertiary -left-2 ${
            metadata?.startOfUnit
              ? 'h-[120%] top-0'
              : metadata?.endOfUnit
              ? 'h-[120%] bottom-0'
              : 'h-[120%] top-1/2 transform-gpu -translate-y-1/2'
          }`}
        />
      );
    case EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromWholeGamePack:
    case undefined:
    case null:
      return null;
    default:
      assertExhaustive(unit);
      return null;
  }
}

function BlockEntry(props: {
  block: Block;
  index: number;
  onEdit: (block: Block) => void;
  onMove: (from: number, to: number) => void;
  onDelete: (block: Block) => void;
  active: boolean;
  metadata?: BlockPreviewMetadata;
  brand?: DtoBrand;
  unit: Nullable<EnumsGamePackMakeUnitsFrom>;
  readonly: boolean;
}) {
  const { block, index, metadata, brand } = props;
  const summary = useMemo(() => BlockKnifeUtils.Summary(block), [block]);
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'block',
    hover(item: DragBlockEntry, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (canMove(dragIndex, hoverIndex, hoverBoundingRect, monitor)) {
        props.onMove(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });
  const [collected, drag, drapPreview] = useDrag({
    type: 'block',
    item: () => {
      return { id: block.id, index };
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 'opacity-40' : '',
    }),
  });

  drapPreview(drop(ref));

  const predefinedInstructions = brand?.predefinedBlockData?.find(
    (item) => item.scenario === 'instructions'
  );

  return (
    <div className='relative' ref={ref}>
      <SessionIndicator metadata={metadata} />
      {metadata?.showInstructions && block.brandId && (
        <div
          className={`text-3xs font-bold w-32 h-5 bg-black bg-opacity-80 ${
            !predefinedInstructions ? 'opacity-40' : ''
          } 
          border border-secondary rounded-tl-2.5xl rounded-tr-1.5lg 
        rounded-br-1.5lg rounded-bl-none flex items-center justify-center`}
        >
          Instructions
        </div>
      )}
      <div
        className={`flex items-center gap-2.5 w-full relative ${
          collected.opacity
        } p-1 rounded hover:bg-lp-gray-002 cursor-pointer border ${
          props.active
            ? 'bg-lp-gray-002 outline-[1px] border-primary'
            : 'border-transparent'
        }`}
        onClick={() => props.onEdit(block)}
      >
        <LevelRoundIndicator unit={props.unit} metadata={metadata} />
        <div className='flex flex-col self-start items-center'>
          <p className='text-xs font-bold'>{index + 1}</p>
          {!props.readonly && (
            <button
              type='button'
              ref={drag}
              className='btn cursor-move flex-shrink-0'
            >
              <MenuIcon />
            </button>
          )}
        </div>
        <div className='flex flex-grow gap-1'>
          <div className='relative overflow-hidden flex-shrink-0'>
            <BlockCover doc={summary} className='w-22.5 h-12.5' />
          </div>
          <div className='flex flex-grow justify-between'>
            <div className='flex flex-col items-start justify-between my-0.5'>
              <div
                className='text-sms font-bold line-clamp-2'
                style={{ lineHeight: 1.1 }}
              >
                {summary.title}
              </div>
              <div
                className={`text-2xs text-secondary line-clamp-1 ${
                  brand ? 'cursor-pointer hover:text-white' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!brand) return;
                  window.open(`/admin/brands/${brand.id}`, '_blank');
                }}
              >
                {brand?.name ?? 'No Brand'}
              </div>
            </div>
            <div className='flex flex-col flex-shrink-0 items-center justify-between my-0.5'>
              <div className='flex items-center gap-1'>
                <BlockRecordingIcon
                  block={block}
                  className=''
                  iconClassName='w-4 h-4'
                />
                <BlockIcon
                  className='w-4 h-4 flex-shrink-0'
                  blockType={block.type}
                />
              </div>
              {!props.readonly && (
                <button
                  type='button'
                  className='btn-secondary text-red-002 w-6 h-6 rounded-md flex items-center justify-center'
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onDelete(block);
                  }}
                >
                  <DeleteIcon className='w-2.5 h-2.5 fill-current' />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {metadata?.showLeaderboards && (
        <div
          className='text-3xs font-bold w-32 h-5 bg-black bg-opacity-80 
        border border-secondary rounded-tl-none rounded-tr-1.5lg 
        rounded-br-1.5lg rounded-bl-2.5xl flex items-center justify-center'
        >
          Scoreboard
        </div>
      )}
    </div>
  );
}

function BlockListEditor(
  props: GamePackEditorControlledProps & {
    playbackSettings: Nullable<ModelsPlaybackSettings>;
    editingBlockId: string | null;
    brandMap: Map<string, DtoBrand>;
    endOfBlocksRef: RefObject<HTMLDivElement>;
    randomizationPreview?: boolean;
  }
) {
  const { playbackSettings, editingBlockId, brandMap, watch } = props;
  const { move, remove } = useBlocksField();
  const blocksRaw = watch<'blocks'>('blocks');

  const randomizationPreview =
    playbackSettings?.randomizeUnitOrder && props.randomizationPreview;
  const blocks = useMemo(() => {
    if (!randomizationPreview) return blocksRaw;
    return shuffle(blocksRaw);
  }, [blocksRaw, randomizationPreview]);

  const [, setFilter] = useBrandBlockFilter();
  const { blockMetadataMap: metadataMap } = useMakePlaybackPreview(
    blocks,
    playbackSettings
  );

  if (blocks.length === 0) {
    return (
      <div className='text-icon-gray w-full h-15 flex items-center justify-center'>
        Choose blocks from the right panel
      </div>
    );
  }

  return (
    <div
      className={`w-full h-full flex flex-col items-center flex-shrink-0 gap-4`}
    >
      <DndProvider backend={HTML5Backend}>
        {blocks.length > 0 && (
          <div className='flex flex-col w-full gap-2'>
            {blocks.map((b, i) => (
              <BlockEntry
                key={b.id}
                block={b}
                index={i}
                onMove={move}
                onDelete={() => {
                  if (editingBlockId === b.id) {
                    setFilter('editingBlockId', null);
                  }
                  remove(i);
                }}
                onEdit={() => {
                  if (editingBlockId === b.id) {
                    setFilter('editingBlockId', null);
                  } else {
                    setFilter('editingBlockId', b.id, true);
                  }
                }}
                active={editingBlockId === b.id}
                metadata={metadataMap.get(b.id)}
                brand={brandMap.get(b.brandId ?? '')}
                unit={playbackSettings?.makeUnitsFrom}
                readonly={!!randomizationPreview}
              />
            ))}
          </div>
        )}
      </DndProvider>
      <div ref={props.endOfBlocksRef}></div>
    </div>
  );
}

function BlockListEditorWithRandomizationToggle(
  props: GamePackEditorControlledProps & {
    editingBlockId: string | null;
    brandMap: Map<string, DtoBrand>;
    endOfBlocksRef: RefObject<HTMLDivElement>;
  }
) {
  const { watch } = props;

  const blockRandomizationPreview = watch('blockRandomizationPreview');
  const playbackSettings = watch('playbackSettings');

  return (
    <div>
      <div className='text-white mb-2 flex items-center justify-between'>
        <div className='font-bold'>Blocks</div>
        {playbackSettings?.randomizeUnitOrder && (
          <BlockRandomizationPreviewToggle {...props} />
        )}
      </div>
      <BlockListEditor
        {...props}
        playbackSettings={playbackSettings}
        randomizationPreview={blockRandomizationPreview}
      />
    </div>
  );
}

function DetailPreview(
  props: GamePackEditorControlledProps & {
    brandMap: Map<string, DtoBrand>;
  }
) {
  const { watch, brandMap } = props;

  const blocks = watch('blocks');
  const playbackSettings = watch('playbackSettings');
  const replayable = watch('replayable');
  const fakePlayedHistory = useFakePlayedHistory(blocks);

  return (
    <div className='w-full'>
      <div className='text-white font-bold mb-2'>Detail Preview</div>
      <div className='w-full min-h-32 bg-black bg-opacity-40 rounded-xl flex items-center justify-center p-2'>
        {blocks.length === 0 ? (
          <div className='text-secondary text-center'>
            Add a blocks to see preview
            <br /> of game details
          </div>
        ) : (
          <GamePackV2PlaybackConfigurator
            blocks={blocks}
            playbackSettings={playbackSettings}
            brands={brandMap}
            layout='col'
            playedHistory={fakePlayedHistory}
            replayable={!!replayable}
          />
        )}
      </div>
    </div>
  );
}

function GamePackBlockEditor(props: {
  blockId: string;
  isBlockChanging: boolean;
  setIsBlockChanging: (val: boolean) => void;
}) {
  const store = useInstance(() => new BlockEditorStore());
  const [, setFilter] = useBrandBlockFilter();

  const { getValues } = useFormContext<GamePackEditorFormData>();
  const blocksField = useBlocksField();
  const handleBlockChange = useLiveCallback((block: Block) => {
    const blocks = getValues<'blocks'>('blocks');
    const index = blocks.findIndex((b) => b.id === block.id);
    if (index === -1) return;
    blocksField.update(index, block);
  });

  const swr = useSWR(`/blocks/${props.blockId}`, async () => {
    const resp = await apiService.block.getBlock(props.blockId);
    return resp.data.block;
  });

  const loadedBlock = useMemo(
    () => (swr.data ? fromAPIBlockTypes(swr.data) : null),
    [swr.data]
  );

  useLayoutEffect(() => {
    loadedBlock && store.setBlocks(loadedBlock);
  }, [store, loadedBlock]);

  const block = useSnapshot(store.state).blocks.find(
    (b) => b.id === props.blockId
  ) as Block | undefined;

  useLayoutEffect(() => {
    if (!block || !loadedBlock || block === loadedBlock) return;
    handleBlockChange(block);
  }, [handleBlockChange, block, loadedBlock]);

  const asyncDetails = useOndRecordingDetails(
    block ?? null,
    GameUtils.DeriveOndPlaybackVersionFromBlocks(block ? [block] : null),
    async () => {
      const data = await swr.mutate();
      if (data) {
        const updated = fromAPIBlockTypes(data);
        store.setBlocks(updated);
        handleBlockChange(updated);
      }
    }
  );

  return (
    <BlockEditorStoreProvider store={store}>
      {swr.isLoading && <Loading />}
      {block && (
        <div className='flex flex-col gap-4 p-4'>
          <header className='flex items-center justify-end gap-3'>
            {asyncDetails.detailsLink}
            <button
              type='button'
              className='btn-secondary w-30 h-10'
              onClick={() => setFilter('editingBlockId', null)}
            >
              Close
            </button>
          </header>
          <BlockEditor
            block={block}
            setSavingChanges={props.setIsBlockChanging}
          />

          {asyncDetails.detailsModal}
          {asyncDetails.warningModal}
        </div>
      )}
    </BlockEditorStoreProvider>
  );
}

function TemplateField(
  props: GamePackEditorControlledProps & {
    setValue: UseFormSetValue<GamePackEditorFormData>;
  }
) {
  const options = useInstance<Option<string>[]>(() =>
    GPv2TemplateUtils.MakeSelectOptions()
  );

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<string>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <label>
      <div className='mb-2 font-bold'>Playback Settings Template</div>
      <Controller<GamePackEditorFormData, 'extraSettings.templateId'>
        name='extraSettings.templateId'
        control={props.control}
        render={({ field }) => {
          return (
            <Select<Option<string>>
              options={options}
              value={options.find((opt) => opt.value === field.value)}
              classNamePrefix='select-box-v2'
              className='w-full'
              styles={styles}
              placeholder='Select a template'
              onChange={(option) => {
                field.onChange(option?.value);
                const template = GPv2TemplateUtils.GetTemplate(option?.value);
                if (!template) return;
                props.setValue<'playbackSettings'>(
                  'playbackSettings',
                  template.playbackSettings,
                  {
                    shouldDirty: true,
                  }
                );
                props.setValue<'replayable'>(
                  'replayable',
                  template.replayable,
                  {
                    shouldDirty: true,
                  }
                );
              }}
            />
          );
        }}
      />
    </label>
  );
}

function BlockRandomizationPreviewToggle(props: GamePackEditorFieldProps) {
  const { field } = useController<
    GamePackEditorFormData,
    'blockRandomizationPreview'
  >({
    name: 'blockRandomizationPreview',
    control: props.control,
  });

  useEffectOnce(() => {
    field.onChange(true);
  });

  return (
    <label className='flex items-center text-2xs gap-0.5'>
      <span className='select-none cursor-pointer'>Randomization Preview</span>
      <input
        type='checkbox'
        className='checkbox-dark'
        checked={field.value}
        onChange={field.onChange}
      />
    </label>
  );
}

function AIHostSettingsEditor(props: {
  value?: string | null;
  onChange: (value: string | null | undefined) => void;
}) {
  const openShareAssetPickerModal = useOpenShareAssetPickerModal();

  const { data, isLoading, error } = useSWR(
    !props.value ? null : ['/media/shared/', props.value],
    async ([_, id]) => {
      const sharedAsset = await apiService.media.getSharedAsset(id);
      return sharedAsset.data.sharedAsset;
    }
  );

  const handleAdd = useLiveCallback(() => {
    openShareAssetPickerModal({
      purposes: [EnumsSharedAssetPurpose.SharedAssetPurposeVoice],
      onSelected: (item) => {
        props.onChange(item.id);
      },
    });
  });

  const handleDelete = useLiveCallback(() => {
    props.onChange(null);
  });

  return (
    <div className='flex flex-col gap-4'>
      <div className='w-full'>
        <div className='mb-2 w-full flex items-center justify-between'>
          <div className='font-bold'>Voice</div>
          {props.value && (
            <button
              type='button'
              className='btn text-sms text-icon-gray hover:text-primary hover:underline transition-all'
              onClick={handleAdd}
            >
              Edit
            </button>
          )}
        </div>
        {!props.value ? (
          <button
            type='button'
            className='mt-2 btn text-sms text-secondary'
            onClick={handleAdd}
          >
            + Add Voice
          </button>
        ) : isLoading ? (
          <Loading text='' />
        ) : error || !data ? (
          <div className='text-red-002 text-sms text-center'>
            Error loading voice
          </div>
        ) : (
          <div className='w-full flex items-center gap-2'>
            <div className='w-full bg-layer-002 rounded border border-secondary px-4 py-2 flex items-center justify-between'>
              <div className='text-white font-bold'>{data.label}</div>
              <Link
                to={`/admin/toolkit/shared-media?id=${props.value}`}
                target='_blank'
              >
                <div className='transform hover:scale-110 transition-transform'>
                  <NewWindowIcon />
                </div>
              </Link>
            </div>
            <div className='flex-none'>
              <TTSPreviewButton
                script='Hello, world! This is a preview script.'
                settings={data.data?.ttsRenderSettings}
              />
            </div>
            <button
              type='button'
              onClick={handleDelete}
              className='flex-none w-7.5 h-7.5 rounded-lg border border-secondary btn flex justify-center items-center text-red-002 bg-black'
            >
              <DeleteIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function FormConnectedBrandBlockPicker(props: {
  brandMap: Map<string, DtoBrand>;
  scrollToTheEndOfBlocks: () => void;
}) {
  const { brandMap, scrollToTheEndOfBlocks } = props;
  const { getValues, watch } = useFormContext<GamePackEditorFormData>();
  const blocksField = useBlocksField();

  const handlePickBrandBlocks = useLiveCallback(
    (brand: DtoBrand | undefined, ...blocks: DtoBlock[]) => {
      const currBlocks = getValues<'blocks'>('blocks');
      for (const block of blocks) {
        const exist = currBlocks.find((b) => b.id === block.id);
        if (exist) continue;
        blocksField.append(fromAPIBlockTypes(block));
      }
      if (!brand) return;
      brandMap.set(brand.id, brand);
      scrollToTheEndOfBlocks();
    }
  );
  const handleRemoveBrandBlock = useLiveCallback((...blocks: DtoBlock[]) => {
    const currBlocks = getValues<'blocks'>('blocks');
    for (const block of blocks) {
      const index = currBlocks.findIndex((b) => b.id === block.id);
      blocksField.remove(index);
    }
  });

  const blockIds = watch('blocks').map((b) => b.id);
  return (
    <BrandBlockPicker
      pickedBlockIds={blockIds}
      onPick={handlePickBrandBlocks}
      onRemove={handleRemoveBrandBlock}
    />
  );
}

function useBlocksField() {
  const { setValue, getValues } = useFormContext<GamePackEditorFormData>();

  const append = useLiveCallback((block: Block) => {
    const curr = getValues<'blocks'>('blocks');
    setValue<'blocks'>('blocks', [...curr, block], {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  });

  const remove = useLiveCallback((index: number) => {
    const next = getValues<'blocks'>('blocks');
    next.splice(index, 1);
    setValue<'blocks'>('blocks', next, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  });

  const update = useLiveCallback((index: number, block: Block) => {
    setValue(`blocks.${index}`, block, {
      // do not dirty. we don't care about the block content changing...it is saved elsewhere.
      shouldValidate: true,
    });
  });

  const move = useLiveCallback((from: number, to: number) => {
    const curr = getValues<'blocks'>('blocks');
    const next = [...curr];
    const [removed] = next.splice(from, 1);
    next.splice(to, 0, removed);
    setValue<'blocks'>('blocks', next, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  });

  return { append, remove, update, move };
}

function CohostSettingsEditor(props: GamePackEditorControlledProps) {
  return (
    <section className='flex flex-col gap-4'>
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between items-center'>
          <p className='font-bold'>Cohosting Enabled</p>
          <Controller<GamePackEditorFormData, 'cohostSettings.enabled'>
            name='cohostSettings.enabled'
            control={props.control}
            render={({ field }) => (
              <SwitcherControlled
                name={field.name}
                checked={field.value || false}
                onChange={field.onChange}
              />
            )}
          />
        </div>
        <div className='text-icon-gray text-xs'>
          When enabled, this game pack's appearance, library placement, and
          purchase/schedule flow will be updated to enable live cohosting.
        </div>
      </div>
    </section>
  );
}

function UGCPrompteTemplateEditor(props: {
  value?: string | null;
  onChange: (value: string | null | undefined) => void;
}) {
  const openPicker = useOpenPromptTemplatePickerModal();

  const { data, isLoading, error } = usePromptTemplate(props.value, false);

  const handleAdd = useLiveCallback(() => {
    openPicker({
      onSelected: (item) => {
        props.onChange(item.id);
      },
    });
  });

  const handleDelete = useLiveCallback(() => {
    props.onChange(null);
  });

  return (
    <div className='flex flex-col gap-4'>
      <div className='w-full'>
        <div className='mb-2 w-full flex items-center justify-between'>
          <div className='font-bold'>Select an AI Prompt Template</div>
          {props.value && (
            <button
              type='button'
              className='btn text-sms text-icon-gray hover:text-primary hover:underline transition-all'
              onClick={handleAdd}
            >
              Edit
            </button>
          )}
        </div>
        {!props.value ? (
          <button
            type='button'
            className='mt-2 btn text-sms text-secondary'
            onClick={handleAdd}
          >
            + Select from prompt library
          </button>
        ) : isLoading ? (
          <Loading text='' />
        ) : error || !data ? (
          <div className='text-red-002 text-sms text-center'>
            Error loading prompt template
          </div>
        ) : (
          <div className='w-full flex items-center gap-2'>
            <div className='w-full bg-layer-002 rounded border border-secondary px-4 py-2 flex items-center justify-between'>
              <div className='text-white font-bold'>{data.name}</div>
              <Link
                to={`/admin/prompt-templates/${props.value}`}
                target='_blank'
              >
                <div className='transform hover:scale-110 transition-transform'>
                  <NewWindowIcon />
                </div>
              </Link>
            </div>
            <button
              type='button'
              onClick={handleDelete}
              className='flex-none w-7.5 h-7.5 rounded-lg border border-secondary btn flex justify-center items-center text-red-002 bg-black'
            >
              <DeleteIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export function GamePackV2Editor(props: {
  onClose: (pack?: GamePack | null) => Promise<void>;
  onSubmit?: (pack: GamePack) => void;
  pack?: GamePack | null;
  brands?: DtoBrand[];
  blocks?: DtoBlock[];
  faqGroups?: FAQGroup[];
  anonymousFAQGroups?: FAQGroup[];
  showcaseCards?: GamePackShowcaseCard[];
}) {
  const { pack } = props;
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isBlockChanging, setIsBlockChanging] = useState<boolean>(false);
  const [overlay, setOverlay] = useState<JSX.Element | null>(null);

  const form = useForm<GamePackEditorFormData>({
    defaultValues: GamePackEditorUtils.DefaultGamePackFormValues(
      pack,
      EnumsGamePackVersion.GamePackVersionV2,
      props.blocks?.map((b) => fromAPIBlockTypes(b)),
      props.faqGroups,
      props.anonymousFAQGroups,
      props.showcaseCards
    ),
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isDirty },
  } = form;

  const brandMap = useBrandMap(props.brands ?? []);

  const {
    call: submit,
    state: { state: submitState, error: submitError },
    reset: resetSubmit,
  } = useSubmit(pack?.id);

  const onSubmit = (confirm?: {
    level: EnumsGamePackChangeLevel;
    note?: string;
  }) => {
    handleSubmit(async (data: GamePackEditorFormData) => {
      const resp = await submit(data, confirm);
      if (!resp) return;
      if (props.onSubmit) {
        props.onSubmit(resp);
      }
      reset(data);
    })();
  };

  const confirmCancel = useTriggerCancelConfirmModal();
  const onCancel = async () => {
    if (isDirty) {
      const response = await confirmCancel();
      if (response.result === 'canceled') return;
    }
    props.onClose();
  };

  const errors: Error[] = [];
  if (submitError) errors.push(submitError);

  const resetErrors = () => {
    resetSubmit();
  };

  const [searchParams] = useSearchParams();
  const editingBlockId = searchParams.get('editingBlockId');
  const q = searchParams.get('q');

  const disabledReason = errors.length
    ? 'errors'
    : submitState.isRunning || isBlockChanging
    ? 'processing'
    : isUploading
    ? 'loading'
    : null;

  const ref = useRef<HTMLDivElement>(null);
  const scrollToTheEndOfBlocks = () => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  return (
    <FormProvider<GamePackEditorFormData> {...form}>
      <form className='w-full h-full bg-black fixed flex flex-col z-50 top-0 bg-library'>
        <Header
          q={q}
          pack={pack}
          onCancel={onCancel}
          onSubmit={onSubmit}
          disabledReason={disabledReason}
          needConfirm={!!pack?.id}
        />
        <PrimeWarningMessage gameLike={pack} />
        <BannerWarningMessages errors={errors} reset={resetErrors} />
        <div className='flex-grow min-h-0 flex flex-row w-full'>
          <div
            className={`w-1/4 min-w-100 relative overflow-y-auto scrollbar text-white px-7 bg-white bg-opacity-5`}
          >
            <div className={`w-full flex flex-col items-center`}>
              <div className='w-full flex flex-col gap-6 my-7.5'>
                <PackNameField
                  control={control}
                  register={register}
                  watch={watch}
                />
                <BlockListEditorWithRandomizationToggle
                  {...form}
                  editingBlockId={editingBlockId}
                  brandMap={brandMap}
                  endOfBlocksRef={ref}
                />
                <DetailPreview {...form} brandMap={brandMap} />
                <TemplateField
                  control={control}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                />
                <CollapsibleSection
                  title='Playback Settings'
                  defaultOpened={false}
                >
                  <PlaybackSettingsEditor
                    control={control}
                    register={register}
                    watch={watch}
                  />
                </CollapsibleSection>
                <CollapsibleSection title='Game Details' defaultOpened={false}>
                  <DetailSettingsEditor
                    pack={pack}
                    control={control}
                    register={register}
                    watch={watch}
                    setIsUploading={setIsUploading}
                    onShowOverlay={setOverlay}
                  />
                </CollapsibleSection>
                <CollapsibleSection title='Team Settings' defaultOpened={false}>
                  <TeamSettingsEditor
                    control={control}
                    register={register}
                    watch={watch}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title='One Time Purchase Price'
                  defaultOpened={false}
                >
                  <OneTimePurchasePriceEditor packId={pack?.id} />
                </CollapsibleSection>
                <CollapsibleSection
                  title='Marketing Meta Data'
                  defaultOpened={false}
                >
                  <GamePackMarketingSettingsEditor
                    control={control}
                    register={register}
                    watch={watch}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title='AI Host Settings'
                  defaultOpened={false}
                >
                  <Controller<GamePackEditorFormData, 'aiHostSettings.voiceId'>
                    name='aiHostSettings.voiceId'
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <AIHostSettingsEditor
                          value={value}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title='Live Cohost Settings'
                  defaultOpened={false}
                >
                  <CohostSettingsEditor
                    control={control}
                    register={register}
                    watch={watch}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title='Custom Game Settings'
                  defaultOpened={false}
                >
                  <Controller<
                    GamePackEditorFormData,
                    'ugcSettings.promptTemplateId'
                  >
                    name='ugcSettings.promptTemplateId'
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <UGCPrompteTemplateEditor
                          value={value}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                  <Controller<
                    GamePackEditorFormData,
                    'ugcSettings.userDirection'
                  >
                    name='ugcSettings.userDirection'
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <label>
                          <div className='mb-2 font-bold'>
                            Short Directions for User
                          </div>
                          <input
                            className='field h-13 mb-0'
                            onChange={onChange}
                            placeholder='Type Directions'
                            value={value ?? ''}
                          />
                        </label>
                      );
                    }}
                  />
                </CollapsibleSection>
              </div>
            </div>
          </div>
          <div className='flex-1 h-full overflow-auto scrollbar'>
            {editingBlockId ? (
              <GamePackBlockEditor
                blockId={editingBlockId}
                isBlockChanging={isBlockChanging}
                setIsBlockChanging={setIsBlockChanging}
              />
            ) : (
              <FormConnectedBrandBlockPicker
                brandMap={brandMap}
                scrollToTheEndOfBlocks={scrollToTheEndOfBlocks}
              />
            )}
          </div>
        </div>
      </form>
      {overlay}
    </FormProvider>
  );
}
